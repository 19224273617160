export enum SchemaKeywords {
  UI = 'ui',
  UiIcon = '_ui_icon',
  UiWidget = '_ui_widget',
  UiTag = '_ui_tag',
  Readme = '_readme',
  UiGroup = '_ui_group',
  UiHide = '_ui_hide',
  Component = 'component',
}

export enum SfoUiWidgetType {
  DateInput = 'sfo-date-input',
  CodeEditor = 'sfo-code-editor',
  RichText = 'sfo-rich-text',
  Textarea = 'sfo-textarea',
  RadioGroup = 'sfo-radio-group',
}

// TODO: https://gitlab.com/sciflow/ee/development/-/issues/1019
