import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  signal,
} from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import {
  BehaviorSubject,
  combineLatest,
  filter,
  map,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';

import { SfoUiJSONSchema7 } from '../metadata.model';
import { SfoFormHelperService } from './form-helper.class';
import { FormService } from './form.service';
import { RenderMode } from './types/view.types';

@Component({
  selector: 'sfo-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class DynamicFormComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  private formService: FormService = inject(FormService);
  private formValue$: BehaviorSubject<object> = new BehaviorSubject({});
  private cd: ChangeDetectorRef = inject(ChangeDetectorRef);

  schema$ = new BehaviorSubject<SfoUiJSONSchema7 | null>(null);

  formReady = signal<boolean>(false);
  formErrors = signal<string | null>(null);
  schemaErrors = signal<string | null>(null);

  parentForm: FormGroup = new FormGroup({});

  /**
   * Sets the JSON schema and triggers a change detection in the component.
   * @param data - The JSON schema data to be set. It is cast to `JSONSchema7Ui` type.
   */
  @Input() set jsonSchema(data: unknown) {
    this.schema$.next(data as SfoUiJSONSchema7);
  }

  /**
   * The view type for the settings. This determines the layout or mode in which settings are displayed.
   * `default` - Vertical navigation using drawer
   * `tabbed` - Horizontal navigation using tabs
   * `simple` - Single column, max width
   * `quickSettings` - Single column, high level children are wrapped in panels
   * @type {RenderMode}
   * @default 'settings'
   */
  @Input() renderMode: RenderMode = 'default';

  /**
   * Patches the current internally generated FormGroup with a new value and triggers a change detection in the component.
   * @param formValue - The value to be set in the form. If null, no action is taken.
   */
  @Input() set patchForm(formValue: object | null) {
    if (!formValue) return;
    this.formValue$.next(formValue);
  }

  /**
   * Emits an event with the current `FormGroup`.
   * @type {EventEmitter<FormGroup>}
   */
  @Output() formValue = new EventEmitter<object>();

  /**
   * Emits an event when the patch is invalid
   * @type {EventEmitter<FormGroup>}
   */
  @Output() patchError = new EventEmitter<unknown | unknown[]>();

  ngOnInit(): void {
    this.schema$
      .pipe(
        takeUntil(this.destroy$),
        filter(Boolean),
        tap(() => this.initializeForm()),
        switchMap((schema) =>
          this.parentForm.valueChanges.pipe(
            filter(() => this.parentForm.valid && this.formReady()),
            map((formValue) => {
              const cleanedFormValues = SfoFormHelperService.removeNullValues(formValue, schema);
              const cleanedDefaults = SfoFormHelperService.removeDefaults(
                cleanedFormValues,
                schema,
              );
              return cleanedDefaults;
            }),
          ),
        ),
      )
      .subscribe((processedValue) => {
        if (!processedValue) return;
        this.formValue.emit(processedValue);
      });

    combineLatest([this.schema$, this.formValue$])
      .pipe(
        takeUntil(this.destroy$),
        filter(([schema, patch]) => Boolean(schema)),
        tap(([schema, patch]) => this.handleFormPatch(schema, patch)),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private initializeForm(): void {
    this.schemaErrors.set(null);
    this.formErrors.set(null);

    const schema = this.schema$.getValue();
    if (!schema) {
      this.schemaErrors.set('The schema provided is empty.');
      return;
    }

    this.formReady.set(false);

    if (this.parentForm) {
      this.parentForm.reset();
    }

    try {
      this.parentForm = this.formService.generateForm(schema);
      this.formReady.set(true);
    } catch (e) {
      console.error(e);
      this.formErrors.set(e?.['message']);
    }
    this.cd.markForCheck();
  }

  private handleFormPatch(schema: any, patch: any): void {
    try {
      const isPatchValid = SfoFormHelperService.isPatchValid(schema, patch);
      if (!isPatchValid?.isValid) {
        this.patchError.emit(isPatchValid?.errors);
        return;
      }

      this.syncFormArrayControls(this.parentForm, patch);

      this.formErrors.set(null);
      this.parentForm.patchValue(patch, { emitEvent: false });
      this.cd.markForCheck();
    } catch (e) {
      this.patchError.emit(e);
      this.formErrors.set(e);
    }
  }

  /**
   * Patches form array controls with patch data without triggering value emissions when creating new controls.
   * @param form - The form to synchronize array controls in
   * @param patch - The patch data containing array values
   */
  private syncFormArrayControls(form: AbstractControl, patch: any): void {
    if (!(form instanceof FormGroup)) return;

    Object.keys(form.controls).forEach((key) => {
      const control = form.get(key);
      const patchValue = patch[key];

      if (control instanceof FormArray && Array.isArray(patchValue)) {
        const fullPath = this.formService.getControlPath(form, key);
        const schema = this.schema$.getValue();
        const propertySchema = this.formService.findPropertySchema(schema, fullPath);

        if (propertySchema) {
          // First, adjust the FormArray length to match the patch data
          const currentLength = control.length;
          const targetLength = patchValue.length;

          if (currentLength < targetLength) {
            // Add new controls if needed
            for (let i = currentLength; i < targetLength; i++) {
              if (propertySchema.items) {
                const newControl = this.formService.buildFormGroup(propertySchema.items);
                control.push(newControl, { emitEvent: false });
              }
            }
          } else if (currentLength > targetLength) {
            // Remove excess controls
            for (let i = currentLength - 1; i >= targetLength; i--) {
              control.removeAt(i, { emitEvent: false });
            }
          }

          // Now patch each control in the array
          patchValue.forEach((value: any, index: number) => {
            const arrayControl = control.at(index);
            if (arrayControl instanceof FormGroup) {
              this.syncFormArrayControls(arrayControl, value);
            } else {
              arrayControl.patchValue(value, { emitEvent: false });
            }
          });
        }
      } else if (control instanceof FormGroup && patchValue) {
        this.syncFormArrayControls(control, patchValue);
      }
    });
  }
}
