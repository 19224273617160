<div class="flex flex-column" style="height: 100%;">
  <h2 class="flex items-center mb0 px2 pt2">
    @switch (sfNodeType()) {
      @case ('part') {
        Chapter Settings
        <div class="flex-auto"></div>
        @if (commands().length) {
          @for (command of commands(); track command.id) {
            <button
              mat-icon-button
              (click)="executeCommand(command)"
              [matTooltip]="command.tooltip"
              class="ml2"
            >
              <mat-icon [class.negative]="command.id === 'delete'">
                {{command.materialIconName}}
              </mat-icon>
            </button>
          }
        }
      }
      @default {
        Edit
      }
    }
  </h2>

  <mat-dialog-content class="col-12">
    <sfo-dynamic-form
      [jsonSchema]="nodeMetaDataSchema()"
      [renderMode]="renderMode"
      [patchForm]="nodeMetaData()"
      (formValue)="updateForm($event)"
    ></sfo-dynamic-form>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button (click)="onCancel()">Cancel</button>
    <button mat-raised-button color="primary" (click)="onSave()">Save</button>
  </mat-dialog-actions>
</div>
