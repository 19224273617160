import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { Injector } from '@angular/core';
import { Plugin, PluginKey } from 'prosemirror-state';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DOCUMENT_PORTAL_DATA } from '../pm-editor.component';
import { matchNode } from './helpers/matchNode';

export const structuringMenuPluginKey = new PluginKey('structuringMenu');

export interface MenuConfig<T> {
  /**
   * The Angular component to render in the overlay.
   */
  component: ComponentType<T>;
  /**
   * Configuration options for the Angular CDK Overlay position strategy.
   */
  positionOptions?: {
    offsetX?: number;
    positions?: Array<{
      overlayX: 'start' | 'center' | 'end';
      overlayY: 'top' | 'center' | 'bottom';
      originX: 'start' | 'center' | 'end';
      originY: 'top' | 'center' | 'bottom';
    }>;
  };
  /**
   * Configuration options for the Angular CDK Overlay.
   */
  overlayConfig?: {
    hasBackdrop?: boolean;
    backdropClass?: string;
    maxHeight?: string;
    maxWidth?: string;
  };
}

interface MenuState {
  type?: string;
  id: string;
  pos: number;
  coords: { top: number; left: number };
  node: any;
  inFilteredNode: boolean;
  parent?: any;
  el: any;
  exec?: (command: any) => void;
}

/**
 * The plugin listens to view updates and DOM events to determine when to attach,
 * reposition, or detach the overlay. It passes updated state to the overlay component
 * via Angular's ComponentPortal and a custom injector.
 *
 * @template T The type of the Angular component for the menu.
 * @param overlay The Angular CDK Overlay service.
 * @param injector The Angular injector.
 * @param menuConfig Configuration for the menu including which component to render.
 * @param execCommand Callback for command execution, receiving the command and node position.
 * @returns A ProseMirror Plugin that encapsulates overlay behavior.
 */
export const createOverlayMenuPlugin = <T>(
  overlay: Overlay,
  injector: Injector,
  menuConfig: MenuConfig<T>,
  execCommand: (command: any, pos: number) => void,
) => {
  const menuEvents = new Subject<MenuState | null>();
  let overlayRef: OverlayRef | null = null;

  const defaultConfig: MenuConfig<T> = {
    component: menuConfig.component,
    positionOptions: {
      offsetX: -70,
      positions: [
        {
          overlayX: 'end',
          overlayY: 'top',
          originX: 'start',
          originY: 'top',
        },
      ],
    },
    overlayConfig: {
      hasBackdrop: false,
      backdropClass: 'structuring-overlay',
      maxHeight: '1px',
      maxWidth: '1px',
    },
  };

  const config = { ...defaultConfig, ...menuConfig };

  const showMenu = (state: MenuState) => {
    if (!overlayRef) {
      overlayRef = overlay.create({
        ...config.overlayConfig,
        scrollStrategy: overlay.scrollStrategies.reposition(),
      });
    }

    let positionStrategy;

    if (state?.el && state?.el.getBoundingClientRect().width > 0) {
      positionStrategy = overlay
        .position()
        .flexibleConnectedTo(state?.el)
        .withPositions(config.positionOptions?.positions!)
        .withDefaultOffsetX(config.positionOptions?.offsetX || 0);
    }

    overlayRef.updatePositionStrategy(positionStrategy);

    if (overlayRef.hasAttached()) {
      overlayRef.detach();
    }

    // Merge in new coordinates based on the element's DOMRect.
    const updatedData = {
      ...state,
      coords: state.el
        ? state.el.getBoundingClientRect()
        : {
            top: state.coords?.top || 0,
            left: state.coords?.left || 0,
          },
    };

    // Create a custom injector to pass the updated data to the overlay component.
    const customInjector = Injector.create({
      providers: [{ provide: DOCUMENT_PORTAL_DATA, useValue: updatedData }],
      parent: injector,
    });

    const menuPortal = new ComponentPortal(config.component, null, customInjector);

    overlayRef.attach(menuPortal);
  };

  const cleanup = () => {
    menuEvents.next(null);
    overlayRef?.detach();
  };

  menuEvents.pipe(debounceTime(250), distinctUntilChanged()).subscribe((state) => {
    if (!state) {
      cleanup();
      return;
    }

    showMenu({
      ...state,
      exec: (command) => execCommand(command, state.pos),
    });
  });

  return new Plugin<MenuState>({
    key: structuringMenuPluginKey,
    props: {
      handleDOMEvents: {
        mouseleave: (view, event: Event) => {
          if (event instanceof MouseEvent) {
            const el: HTMLElement = view.dom as HTMLElement;
            const rect = el.getBoundingClientRect();

            const extendedLeftBoundary = rect.left - 135; // Extend the bounds on the left side so menu doesn't close when hovering on the left of the element
            const decreasedRightBoundary = rect.right - 135; // Decrease the bounds on the right side so menu closes, removes overlay eagerly before we reach to DCA

            if (
              event.clientX < extendedLeftBoundary ||
              event.clientX > decreasedRightBoundary ||
              event.clientY < rect.top ||
              event.clientY > rect.bottom
            ) {
              cleanup();
            }
          }
        },
      },
    },

    view(_editorView) {
      return {
        update(view, prevState) {
          const { selection } = view.state;

          if (
            prevState &&
            prevState.doc.eq(view.state.doc) &&
            prevState.selection.eq(view.state.selection)
          ) {
            return;
          }

          const matchedNode = matchNode(view, selection.from);
          if (!matchedNode || matchedNode?.inFilteredNode) {
            cleanup();
            return;
          }

          const el = document.getElementById(matchedNode.id);
          if (!el) {
            return;
          }

          menuEvents.next({
            ...matchedNode,
            el,
            coords: matchedNode.coords,
          });
        },

        destroy() {
          menuEvents.complete();
          if (overlayRef) {
            overlayRef.dispose();
            overlayRef = null;
          }
        },
      };
    },
  });
};
