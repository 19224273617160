<div [ngClass]="{'lg-col-8 md-col-10 col-12' : renderMode !== 'quickSettings'}">
  @if (aFormControl || !schema?.[SchemaKeywords.UiHide]) {
    @switch (schema?.type) {
      @case ('object') {
        <ng-template
          *ngTemplateOutlet="
            objectTemplate;
            context: {
              $implicit: {
                aFormGroup: aFormControl?.get(control),
                schema: schema?.properties,
                controlName: control,
                depth: 0
              }
            }
            "
        ></ng-template>
      }

      @case ('array') {
        <ng-template
          *ngTemplateOutlet="
            arrayTemplate;
            context: {
              $implicit: {
                aFormArray: aFormControl?.get(control),
                schema: schema?.properties,
                controlName: control,
                title: schema?.['title']
              }
            }
          "
        ></ng-template>
      }

      @default {
        <ng-template
          *ngTemplateOutlet="
            primitiveTemplate;
            context: {
              $implicit: {
                aFormControl: aFormControl?.get(control),
                schema: schema,
                controlName: control
              }
            }
            "
        ></ng-template>
      }
    }
  }
</div>

<!-- Object Template -->
<ng-template #objectTemplate let-context>
  @for (prop of (context.schema | unsortedKeyvalue); track prop.key) {
    @switch (prop.value['type']) {
      @case ('object') {
        <div class="mt2 col-12">
          <!-- Quick Settings -->
          @if (renderMode === 'quickSettings') {
            <ng-template *ngTemplateOutlet="dynamicField"></ng-template>
          }

          <!-- Settings View -->
          @if (renderMode !== 'quickSettings') {
            <!-- If root property of object -->
            @if (context.depth === 0) {
              <!-- Check if the properties has more than one property -->
              @if (prop.value.properties | hasMultipleKeys) {
                <!-- First level node with more than one property - construct panel -->
                <mat-expansion-panel class="mat-elevation-z0" togglePosition="after">
                  <mat-expansion-panel-header class="mat-panel-header">
                    <mat-panel-title class="mat-panel-title">
                      {{prop?.value?.title ?? prop?.key | titlecase}}
                    </mat-panel-title>

                    @if (prop.value.description) {
                      <mat-panel-description
                        class="mat-panel-description"
                        [matTooltip]="prop.value.description"
                      >
                        {{ prop.value.description }}
                      </mat-panel-description>
                    }
                  </mat-expansion-panel-header>

                  <div class="pt2 mat-panel-body">
                    <ng-template *ngTemplateOutlet="dynamicField"></ng-template>
                  </div>
                </mat-expansion-panel>
              } @else {
                <!-- Content to show if there's no panel, or simply nothing -->
                <div class="m0">
                  <h2 class="my3 h2">
                    {{prop?.value?.title ?? prop?.key | titlecase}}
                  </h2>
                  <ng-template *ngTemplateOutlet="dynamicField"></ng-template>
                </div>
              }
            } @else {
              <div class="m0 pl2 py2">
                <h3 class="m0" [ngClass]="{'h4': context.depth === 1, 'h5': context.depth === 2}">
                  {{prop?.value?.title ?? prop?.key | titlecase}}
                </h3>

                @if (prop.value.description) {
                  <p *ngIf="prop.value?.['description']" class="small">
                    {{ prop.value["description"] }}
                  </p>
                }
              </div>
              <ng-template *ngTemplateOutlet="dynamicField"></ng-template>
            }
          }
          <ng-template #dynamicField>
            <ng-template
              *ngTemplateOutlet="objectTemplate;
              context: {
                $implicit: {
                  aFormGroup: context.aFormGroup?.get(prop.key),
                  schema: prop.value?.['properties'],
                  depth: context.depth + 1,
                  default: context?.default?.[prop.key] || context?.default || '',
                  controlName: prop.key
                },
              }"
            ></ng-template>
          </ng-template>
        </div>
      }

      @case ('array') {
        @if (context.depth === 0 && renderMode !== 'quickSettings') {
          <div class="pb2 col-12">
            <mat-expansion-panel class="mat-elevation-z0" togglePosition="after">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ prop?.value?.title || prop?.key | titlecase }}
                </mat-panel-title>

                @if (prop.value.description) {
                  <mat-panel-description
                    class="mat-panel-description"
                    [matTooltip]="prop.value.description"
                  >
                    {{ prop.value.description }}
                  </mat-panel-description>
                }
              </mat-expansion-panel-header>
              <ng-template *ngTemplateOutlet="dynamicField"></ng-template>
            </mat-expansion-panel>
          </div>
        } @else {
          <ng-template *ngTemplateOutlet="dynamicField"></ng-template>
        }
        <ng-template #dynamicField>
          <ng-template
            *ngTemplateOutlet="arrayTemplate;
            context: {
              $implicit: {
                aFormArray: context.aFormGroup?.get(prop.key),
                schema: prop.value?.['items'],
                controlName: prop.key,
                title: prop.value?.['title'],
                default: context?.default?.[prop.key] || prop.value?.['default']
              }
            }"
          ></ng-template>
        </ng-template>
      }

      @default {
        <div [ngClass]="{'table-cell': context.view === 'table', 'mt2': context.view !== 'table'}">
          <ng-template
            *ngTemplateOutlet="
              primitiveTemplate;
              context: {
                $implicit: {
                  aFormControl: context.aFormGroup?.get(prop.key),
                  schema: prop.value,
                  controlName: prop.key,
                  view: context.view || '',
                  default: context?.default?.[prop.key]
                }
              }
            "
          ></ng-template>
        </div>
      }
    }
  }
</ng-template>

<!-- Array Template -->
<ng-template #arrayTemplate let-context>
  <div class="col-12">
    <div class="table col-12">
      <div class="flex items-center flex-wrap justify-between pb1 table-row">
        <span *ngIf="context.title">
          {{context.title}}
        </span>
        <div id="empty-space" class="flex-grow"></div>
        <button mat-raised-button (click)="addRow(context.aFormArray, context.schema)">Add</button>
      </div>

      <!-- Column Headers -->
      <div class="table-row col-12 flex flex-auto table-header py1">
        <ng-container *ngFor="let prop of context.schema?.['properties'] | unsortedKeyvalue">
          <div class="table-cell bold">
            {{prop.value.title || prop.key}}
          </div>
        </ng-container>
        <div id="empty-space" class="flex items-center justify-center col-1"></div>
      </div>

      <!-- Rows -->
      @for (control of context.aFormArray.controls; track control; let i = $index) {
        <div class="table-row col-12 flex flex-auto py1">
          @switch (context.schema?.type) {
            @case ('object') {
              <ng-template
                *ngTemplateOutlet="
                objectTemplate;
                context: {
                  $implicit: {
                    aFormGroup: context.aFormArray.at(i),
                    schema: context.schema?.properties,
                    controlName: context.controlName,
                    view: 'table',
                    default: context?.default?.[i] || null
                  }
                }
              "
              ></ng-template>
            }
            @case ('array') {
              <div class="flex items-center flex-wrap justify-between mx2">
                <h3 class="h4 mb0">{{ control?.['title'] }}</h3>
                <div id="empty-space" class="flex-grow"></div>
                <button mat-stroked-button (click)="addRow(context.aFormArray, context.schema)">
                  Add
                </button>
              </div>
              <ng-template
                *ngTemplateOutlet="
                arrayTemplate;
                context: {
                  $implicit: {
                    aFormArray: context.aFormArray.at(i),
                    schema: context.schema?.items,
                    controlName: context.controlName
                  }
                }
              "
              ></ng-template>
            }
            @default {
              <div class="table-cell bold">
                <ng-template
                  *ngTemplateOutlet="
                primitiveTemplate;
                context: {
                  $implicit: {
                    aFormControl: context.aFormArray.at(i),
                    schema: context.schema,
                    default: context?.default?.[i] || ''
                  }
                }
                "
                ></ng-template>
              </div>
            }
          }
          <div class="flex items-center justify-center col-1 item-actions">
            <button mat-icon-button (click)="removeRow(i, context.aFormArray)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>
      }
    </div>
  </div>
</ng-template>

<!-- Primitive Template -->
<ng-template #primitiveTemplate let-context>
  <div class="col-12">
    <sfo-dynamic-input
      [aFormControl]="context.aFormControl"
      [schema]="context.schema"
      [defaultValue]="context.default"
    ></sfo-dynamic-input>
  </div>
</ng-template>
