import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import { SfoUiJSONSchema7 } from '../../metadata.model';
import { DynamicFieldModule } from '../form-fields';
import { RenderMode } from '../types/view.types';

interface TabConfig {
  label: string;
  controls: string[]; // a group may contain multiple controls
  description?: string;
  icon?: string;
  isGroup?: boolean;
}

@Component({
  selector: 'sfo-tab-view',
  templateUrl: './tab-view.component.html',
  styleUrls: ['./tab-view.component.scss'],
  imports: [CommonModule, DynamicFieldModule, MatTabsModule],
})
export class TabViewComponent implements OnInit {
  @Input() schema: SfoUiJSONSchema7;
  @Input() aFormControl: FormGroup;
  @Input() renderMode: RenderMode | undefined;

  tabs: TabConfig[] = [];

  ngOnInit(): void {
    this.generateTabs();
  }

  private generateTabs(): void {
    const properties = this.schema?.properties || {};
    const groupedFields = new Map<string, string[]>();

    Object.entries(properties).forEach(([key, value]: [string, any]) => {
      if (value?.['_ui_hide']) {
        return;
      }

      const group = value?.['_ui_group'];
      if (group) {
        if (!groupedFields.has(group)) {
          groupedFields.set(group, []);
        }
        groupedFields.get(group)?.push(key);
      } else {
        this.tabs.push({
          label: value.title || key,
          controls: [key],
          description: value.description,
          icon: value['_ui_icon'],
          isGroup: false,
        });
      }
    });

    groupedFields.forEach((controls, groupName) => {
      this.tabs.push({
        label: groupName,
        controls: controls,
        isGroup: true,
      });
    });
  }

  getFormControl(control: string): FormGroup {
    return this.aFormControl.get(control) as FormGroup;
  }
}
