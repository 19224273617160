@if (!schema[SchemaKeywords.UiHide]) {
  @if (schema[SchemaKeywords.UiWidget] || schema.format) {
    <div class="col-12">
      <!-- date -->
      @if (schema[SchemaKeywords.UiWidget] === SfoUiWidgetType.DateInput) {
        <sfo-date-input [schema]="schema" [aFormControl]="aFormControl"></sfo-date-input>
      }

      <!-- rich-text -->
      @if (
        schema[SchemaKeywords.UiWidget] === SfoUiWidgetType.RichText || schema.format === SfoUiWidgetType.RichText
      ) {
        <mat-form-field
          floatLabel="always"
          appearance="fill"
          class="col-12"
          subscriptSizing="dynamic"
        >
          <mat-label>{{ schema.title }}</mat-label>
          <sfo-rich-text-input
            [formControl]="aFormControl"
            (focusEvent)="onFocus()"
            (blurEvent)="onBlur()"
          ></sfo-rich-text-input>
          <mat-hint *ngIf="schema.description">{{ schema.description }}</mat-hint>
        </mat-form-field>
      }

      <!-- textarea -->
      @if (
        schema[SchemaKeywords.UiWidget] === SfoUiWidgetType.Textarea ||
        schema[SchemaKeywords.UiWidget] === SfoUiWidgetType.CodeEditor ||
        schema.format === 'textarea'
      ) {
        <sfo-textarea
          [schema]="schema"
          [aFormControl]="aFormControl"
          [defaultValue]="defaultValue"
        ></sfo-textarea>
      }

      @if (schema[SchemaKeywords.UiWidget] === SfoUiWidgetType.RadioGroup) {
        <div class="p2">
          <div class="flex flex-column">
            <label class="h3" *ngIf="schema?.title">{{schema.title}}</label>
            <small *ngIf="schema?.description">{{schema.description}}</small>
          </div>
          <mat-radio-group
            [formControl]="aFormControl"
            class="flex flex-column"
            style="gap: 0.5rem;"
          >
            <mat-radio-button *ngFor="let option of schema.examples" [value]="option?.['value']">
              <span class="block" *ngIf="option?.['label']">{{ option?.['label'] }}</span>
              <small *ngIf="option?.['description']">{{ option?.['description'] }}</small>
            </mat-radio-button>
          </mat-radio-group>
        </div>
      }
    </div>
  } @else {
    <div class="col-12">
      @switch (schema | handleSchemaType) {
        @case ('string') {
          <mat-form-field
            *ngIf="!schema.enum"
            floatLabel="always"
            appearance="fill"
            class="col-12"
            subscriptSizing="dynamic"
            matRipple
          >
            <mat-label>
              {{ schema.title }}
              <ng-container *ngIf="schema?.default && schema?.minLength === 0">
                (Default: {{schema.default || defaultValue}})
              </ng-container>
            </mat-label>
            <input
              matInput
              type="text"
              [formControl]="aFormControl"
              [placeholder]="schema.minLength !== undefined ? '' : (defaultValue || schema.default || '').toString()"
              (focus)="onFocus()"
              (blur)="onBlur()"
            />

            <button
              *ngIf="!(aFormControl.value === schema?.default || aFormControl.value === defaultValue || aFormControl.value === null) && schema.minLength !== undefined"
              matSuffix
              mat-icon-button
              aria-label="Reset to default"
              matTooltip="Reset to default"
              (click)="resetToDefault()"
            >
              <mat-icon>refresh</mat-icon>
            </button>

            <!-- Validation errors -->
            <mat-error *ngIf="aFormControl?.hasError('minlength')">
              Minimum length is {{ schema['minLength'] }} characters.
            </mat-error>
            <mat-error *ngIf="aFormControl?.hasError('maxlength')">
              Maximum length is {{ schema['maxLength'] }} characters.
            </mat-error>
            <mat-error *ngIf="aFormControl?.hasError('pattern')">
              Invalid format. Must be a pattern of {{schema['pattern']}}
            </mat-error>

            <mat-hint>
              <span *ngIf="schema.description">{{schema.description}}</span>
            </mat-hint>
          </mat-form-field>

          <!-- enum -->
          <sfo-enum-input
            *ngIf="schema?.enum"
            [schema]="schema"
            [aFormControl]="aFormControl"
            [defaultValue]="defaultValue"
          ></sfo-enum-input>
        }

        @case ('boolean') {
          <sfo-boolean-input
            [schema]="schema"
            [aFormControl]="aFormControl"
            [defaultValue]="defaultValue"
          ></sfo-boolean-input>
        }

        @case ('integer') {
          <sfo-number-input
            [schema]="schema"
            [aFormControl]="aFormControl"
            [defaultValue]="defaultValue"
          ></sfo-number-input>
        }

        @case ('number') {
          <sfo-number-input
            [schema]="schema"
            [aFormControl]="aFormControl"
            [defaultValue]="schema.default || defaultValue"
          ></sfo-number-input>
        }

        @default {
          <div class="bold red">
            Provided "{{schema}}" has an unsupported type: {{schema | handleSchemaType}}.
          </div>
        }
      }
    </div>
  }
}
